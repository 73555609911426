import Inputmask from "inputmask";

document.addEventListener('DOMContentLoaded', () => {
  const modal = document.getElementById("second-modal");
  const buttons = document.querySelectorAll("#openModalBtn");
  const span = document.getElementsByClassName("modal__close modal__close--second")[0];

  if (!modal || !buttons.length || !span) {
    return;
  }

  buttons.forEach(btn => {
    btn.onclick = function() {
      openModal();
    }
  });

  span.onclick = function() {
    closeModal();
  }

  window.onclick = function(event) {
    if (event.target == modal) {
      closeModal();
    }
  }

  function openModal() {
    modal.style.display = "flex";
    setTimeout(() => {
      modal.classList.add('is-visible');
    }, 10); // Небольшая задержка для запуска перехода
  }

  function closeModal() {
    modal.classList.remove('is-visible');
    setTimeout(() => {
      modal.style.display = "none";
    }, 800); // Задержка для завершения перехода
  }

  let botToken = null;
  let chatId = null;

  window.addEventListener('firebaseDataLoaded', (event) => {
    if (event.detail && event.detail.botToken && event.detail.chatId) {
      botToken = event.detail.botToken;
      chatId = event.detail.chatId;
    } else {
      console.log('firebaseDataLoaded event does not contain botToken or chatId');
    }
  });

  const sendMessage = (name, phone) => {
    if (!botToken || !chatId) {
      alert('Bot token или chat ID отсутствует');
      return;
    }

    const message = `Консультація\n Ім'я: ${name}\n Телефон: ${phone}`;

    if (!message.trim()) {
      alert('Текст сообщения пустой');
      return;
    }

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    const data = {
      chat_id: chatId,
      text: message
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      if (data.ok) {
        alert('Повідомлення відправлено!');
      } else {
        alert('Не вдалося відправити повідомлення. Помилка: ' + data.description);
      }
    })
    .catch(error => {
      console.error('Помилка при відправці повідомлення:', error);
      alert('Помилка при відправці повідомлення');
    });
  };

  const form = document.querySelector('.modal__form--second');
  if (!form) {
    return;
  }

  if (!form.dataset.listenerAdded) {
    form.addEventListener('submit', function(event) {
      event.preventDefault();

      const name = document.getElementById('name-second').value || 'не запонено';
      const phone = document.getElementById('phone-second').value || 'не заповнено';

      if (!botToken || !chatId) {
        alert('Bot token або chat ID відсутні. Будь ласка, спробуйте пізніше.');
        return;
      }

      sendMessage(name, phone);
      closeModal();
      this.reset();
    });

    form.dataset.listenerAdded = true;
  }

  const phoneInput = document.getElementById('phone-second');
  if (!phoneInput) {
    return;
  }

  const im = new Inputmask({ 
    mask: "+38 (999) 999-99-99",
    showMaskOnHover: false,
    showMaskOnFocus: true,
    onincomplete: function() {
      phoneInput.setSelectionRange(4, 4); 
    }
  });
  im.mask(phoneInput);

  phoneInput.addEventListener('focus', function() {
    setTimeout(function() {
      phoneInput.setSelectionRange(4, 4); 
    }, 0);
  });
});