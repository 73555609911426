document.addEventListener('DOMContentLoaded', () => {
  function updateMenu() {
    const menuList = document.querySelector('.site-nav__list');
    const moreList = document.querySelector('.more-menu__list');
    const moreBtn = document.querySelector('.more-menu__btn');

    if (!menuList || !moreList || !moreBtn) return;

    let screenWidth = window.innerWidth;
    moreList.innerHTML = '';

    const breakpoints = [
      { min: 1280, max: 1440, limit: 3 },
      { min: 1440, max: 1660, limit: 4 },
      { min: 1660, max: 1924, limit: 5 },
    ];

    for (let bp of breakpoints) {
      if (screenWidth >= bp.min && screenWidth < bp.max) {
        while (bp.limit <= menuList.children.length) {
          const item = menuList.children[bp.limit];
          if (!item) break;

          item.classList.add('more-menu__item');

          const childLink = item.querySelector('a');
          if (childLink) {
            childLink.classList.add('more-menu__link');
          }

          moreList.appendChild(item);
        }
        return;
      }
    }
    moreBtn.style.display = 'none';
  }

  updateMenu();
  window.addEventListener('resize', updateMenu);
});

document.addEventListener('DOMContentLoaded', () => {
  const dropBox = document.querySelector('.dropBox');
  const burger = document.querySelector('.burger');
  const moreBtn = document.querySelector('.more-menu__container');

  if (burger) {
    const addBurgerHover = () => burger.classList.add('burger-hover');
    const removeBurgerHover = () => burger.classList.remove('burger-hover');

    if (moreBtn) {
      moreBtn.addEventListener('mouseenter', addBurgerHover);
      moreBtn.addEventListener('mouseleave', removeBurgerHover);
    }

    if (dropBox) {
      dropBox.addEventListener('mouseenter', addBurgerHover);
      dropBox.addEventListener('mouseleave', removeBurgerHover);
    }
  }
});
