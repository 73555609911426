{
  "about-us.html": [
    { "title": "Головна", "path": "./" },
    { "title": "Про компанію", "path": "./about-us.html" }
  ],
  "services.html": [
    { "title": "Головна", "path": "./" },
    { "title": "Послуги", "path": "./services.html" }
  ],
  "pr.html": [
    { "title": "Головна", "path": "./" },
    { "title": "Послуги", "path": "./services.html" },
    { "title": "PR", "path": "./pr.html" }
  ],
  "design.html": [
    { "title": "Головна", "path": "./" },
    { "title": "Послуги", "path": "./services.html" },
    { "title": "Design", "path": "./design.html" }
  ],
  "smm.html": [
    { "title": "Головна", "path": "./" },
    { "title": "Послуги", "path": "./services.html" },
    { "title": "SMM", "path": "./smm.html" }
  ],
  "digital.html": [
    { "title": "Головна", "path": "./" },
    { "title": "Послуги", "path": "./services.html" },
    { "title": "Digital", "path": "./digital.html" }
  ],
  "vacancies.html": [
    { "title": "Головна", "path": "./" },
    { "title": "Вакансії", "path": "./vacancies.html" }
  ],
  "testimonials.html": [
    { "title": "Головна", "path": "./" },
    { "title": "Відгуки про нас", "path": "./testimonials.html" }
  ]
}
