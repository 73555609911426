(() => {
  const refs = {
    openMenuBtn: document.querySelector('.header-menu__open'),
    closeMenuBtn: document.querySelector('.header-menu__close'),
    siteNavBtn: document.querySelectorAll('.site-nav__btn'),
    menu: document.querySelector('.burger'),
    contactBtn: document.querySelector('.contact-btn'),
    background: document.querySelector('.background'),
    body: document.querySelector('body'),
  };

  refs.openMenuBtn.addEventListener('click', toggleMenu);
  refs.closeMenuBtn.addEventListener('click', toggleMenu);
  refs.contactBtn.addEventListener('click', toggleMenu);

  function toggleMenu() {
    refs.menu.classList.toggle('open');
    refs.background.classList.toggle('open');
    if (screenWidth <= 1280) {
      refs.body.classList.toggle('no-scroll');
    }
  }

  let screenWidth = window.innerWidth;
  if (screenWidth >= 1280) {
    return;
  }

  refs.siteNavBtn.forEach(button => {
    button.addEventListener('click', function () {
      const dropdown = this.parentElement.nextElementSibling;

      dropdown.classList.toggle('open');
    });
  });
})();
