import { initializeApp, getApps, getApp } from 'firebase/app';
import { getDatabase, ref as databaseRef, get } from 'firebase/database';

import './js/scroll-to-top';
import './js/burgerMenu';
import './js/header';
import './js/modal-second';
import './js/vacancies';
import getBreadcrumbs from './js/breadcrumbs';

getBreadcrumbs();

const firebaseConfig = {
  apiKey: 'AIzaSyBSPg3ScFTycIB2Unf4f7_Q1IuNa-HR0c',
  authDomain: 'addway-ecaed.firebaseapp.com',
  databaseURL:
    'https://addway-ecaed-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'addway-ecaed',
  storageBucket: 'addway-ecaed.appspot.com',
  messagingSenderId: '717099611397',
  appId: '1:717099611397:web:3b59ab65c185e28625b36c',
  measurementId: 'G-70MT7EQJME',
};

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}

const database = getDatabase(app);

window.botToken = null;
window.chatId = null;

const botConfigRef = databaseRef(database, 'botConfig');

get(botConfigRef)
  .then(snapshot => {
    const data = snapshot.val();
    if (data) {
      window.botToken =
        typeof data.botToken === 'string'
          ? data.botToken.replace(/"/g, '')
          : data.botToken;
      window.chatId =
        typeof data.chatId === 'string'
          ? data.chatId.replace(/"/g, '')
          : data.chatId;

      const event = new CustomEvent('firebaseDataLoaded', {
        detail: {
          botToken: window.botToken,
          chatId: window.chatId,
        },
      });

      window.dispatchEvent(event);
    } else {
      console.log('No data available');
    }
  })
  .catch(error => {
    console.error('Error retrieving data from Firebase:', error);
  });
