window.addEventListener('firebaseDataLoaded', (event) => {
  window.botToken = event.detail.botToken;
  window.chatId = event.detail.chatId;
});

const sendMessage = (name, phone, type) => {
  if (!window.botToken || !window.chatId) {
    alert('Bot token or chat ID is missing');
    return;
  }

  const message = `${type}:\nName: ${name}\nPhone: ${phone}`;
  
  if (!message.trim()) {
    alert('Текст сообщения пуст');
    return;
  }

  const url = `https://api.telegram.org/bot${window.botToken}/sendMessage`;

  const data = {
    chat_id: window.chatId,
    text: message
  };

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(response => response.json())
  .then(data => {
    if (data.ok) {
      alert('Повідомлення успішно відправлено!');
    } else {
      alert('Не вдалося відправити повідомлення. Помилка: ' + data.description);
    }
  })
  .catch(error => {
    alert('Помилка при відправці повідомлення');
  });
};

const handleFormSubmit = (formId, type) => {
  const form = document.querySelector(`#${formId}`);
  if (form) {
    form.addEventListener('submit', function(event) {
        event.preventDefault(); 

      const name = document.getElementById(`name-${formId}`).value || 'не заповнено';
      const phone = document.getElementById(`phone-${formId}`).value || 'не заповнено';

      sendMessage(name, phone, type);
      this.reset();
    });
  }
};

handleFormSubmit('direct-manager', 'Direct manager');
handleFormSubmit('project-manager', 'Project manager');



function addPhoneValidation(inputId) {
  const inputElement = document.getElementById(inputId);
  if (inputElement) {
    const im = new Inputmask({ 
      mask: "+38 (999) 999-99-99",
      showMaskOnHover: false,
      showMaskOnFocus: true,
      onincomplete: function() {
        inputElement.setSelectionRange(4, 4); 
      }
    });
    im.mask(inputElement);

    inputElement.addEventListener('focus', function() {
      setTimeout(function() {
        inputElement.setSelectionRange(4, 4); 
      }, 0);
    });
  }
}

addPhoneValidation('phone-direct-manager');
addPhoneValidation('phone-project-manager');
addPhoneValidation('phone');