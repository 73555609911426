import breadcrumbs from '../breadcrumbs.json';

export default function getBreadcrumbs() {
  let currentUrl = window.location.pathname;
  const urlArr = currentUrl.split('/');

  const locationArray = breadcrumbs[urlArr[urlArr.length - 1]];

  if (!locationArray) return;

  const breadcrumbWrapper = document.querySelector('.breadcrumb>ul');

  const markup = locationArray
    .map(it => {
      return `<li key="${it.path}"><a href="${it.path}">${it.title}</a></li>`;
    })
    .join('');

  breadcrumbWrapper.innerHTML = markup;
}
